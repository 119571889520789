html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 7px;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 900px) {
  .desktopOnly {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .mobileOnly {
    display: none;
  }
}
